import React from "react"
import * as PropTypes from "prop-types"
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { rhythm } from "../../utils/typography"
import Layout from "../../components/layout"

const propTypes = {
  data: PropTypes.object.isRequired,
}

function getLangDir(locale) {
  const langDir = locale === 'ja' ? 'ja' : 'en';
  return langDir;
}

class ThanksEnPage extends React.Component {
    componentDidMount() {
        if (document.referrer === '') {
            window.location.href = 'https://www.cookinburger.com/en/';
        } else {
            setTimeout(function(){
              window.location.href = document.referrer;//'https://game.cookinburger.com/';
            }, 5*1000);
        }
    }

    render() {
    return (
      <Layout data={this.props.data} location={this.props.location}>
        <Helmet>
          <title>Anyone can play! Multitasking cooking game - Cookin’ Burger</title>
          <meta name="description" content="New PlayMining Platform Game! A multi-tasking cooking burger game!" />
          <script>
            {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1487009848391690');
            fbq('track', 'PageView');
            fbq('track', 'CompleteRegistration');`}
          </script>
          <noscript>{`
            <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1487009848391690&ev=PageView&noscript=1"/>`}
          </noscript>
          <meta name="facebook-domain-verification" content="f4x8mi0ic9i3xuu2qj1vij2jsld4pk" />
        </Helmet>
        <div className="l-main">
            <div className="l-term-section">
                <div className="section-inner_m">
                    <div className="l-term-section-box" style={{marginTop: "10vh"}}>
                    <h2 className="section-title" style={{fontSize: '1.6rem', textAlign:"center", lineHeight: 1.7}}>You are automatically redirect to the game page.<br />
                    If your redirect doesn't happen, please click <a style={{color: '#FFF!important'}} className="game-link" href="https://game.cookinburger.com/"><span className="linkWhite">HERE</span></a></h2>
                    </div>
                </div>
            </div>
        </div>
      </Layout>
    )
  }
}

ThanksEnPage.propTypes = propTypes

export default ThanksEnPage

export const pageQuery = graphql`
  query ThanksEnQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
